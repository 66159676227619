import type { GatsbyBrowser } from "gatsby"

import "./src/assets/app.scss"

const AlumniPath = `/alumni`
export const shouldUpdateScroll: GatsbyBrowser[`shouldUpdateScroll`] = ({
  prevRouterProps,
  routerProps,
}) =>
  // We don't want to scroll to the top of the page if they are just searching for things on
  // the grantees page because it'll force them to constantly scroll down to see the search bar.
  !(
    prevRouterProps?.location.pathname.includes(AlumniPath) &&
    routerProps.location.pathname.includes(AlumniPath)
  )
